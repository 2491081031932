import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../app.config'

export const getMyBaskets = createAsyncThunk(
  'home/getMyBaskets',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/getMyBaskets', {},{ withCredentials:true, headers })
      return response?.data?.data
      
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)

export const listBasketFetch = createAsyncThunk(
  'home/listBasketFetch',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/listBasket', {params},{ withCredentials:true, headers })
      return {
        listBasket: response?.data?.data,
      }
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)
export const detailsBasketFetch = createAsyncThunk(
  'home/detailsBasketFetch',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/detailsBasket', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)

export const addBasketFetch = createAsyncThunk(
  'home/addBasketFetch',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/addBasket', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)
export const previewBasketFetch = createAsyncThunk(
  'home/previewBasketFetch',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/previewBasketFetch', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)


export const closeBasketFetch = createAsyncThunk(
  'home/closeBasketFetch',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/closeBasket', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)
export const openBasketFetch = createAsyncThunk(
  'home/openBasketFetch',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/openBasket', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)

export const fetchListHoldings = createAsyncThunk(
  'home/fetchListHoldings',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/fetchListHoldings', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)

export const fetchSetSipStatus = createAsyncThunk(
  'home/fetchSetSipStatus',
  async (params, { dispatch }) => {
    const headers = {}
    try {
      const response = await axios.post(baseUrl +'/userapi/fetchSetSipStatus', {...params},{ withCredentials:true, headers })
      return  response?.data?.data
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      dispatch(setError(e?.response?.data?.message))
      return false
    }
  }
)


export const Basket = createSlice({
  name: 'basket',
  initialState: {
    myProfile: {},
    proAppForm: {},
    angelOne:null,
    brokerDetails:null,
    dropdownOpen:false,
    myHome: [],
    listBasket: [],
    myBaskets: [],
    detailsBasket: {},
    topTen: [],
    proPlans: [],
    listHoldings:[],
    headerDetails:{},
    myTrick:[],
    TrickDetail:[],
    loader: false,
    homeTrackListLoader:true,
    homeTrackList:[],
    error:'',
    TrackParam:{},
      pageNumber: 1,
      pageSize: 12,
      Search: '',
    total: 0,
    selected: null
  },
  reducers: {
    setSelect: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setTrackParam: (state, action) => {
      state.TrackParam = action.payload
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    setproAppForm: (state, action) => {
      state.proAppForm = action.payload
    },
    setHomeTrackListLoader: (state, action) => {
      state.homeTrackListLoader = action.payload
    },
    setDropDownOpen: (state, action) => {
      state.dropdownOpen = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload.pageNumber
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload.pageSize
    },
    setSearch: (state, action) => {
      state.params = { ...state.params, search: action.payload }
    }
  },  
  extraReducers: (builder) => {
    builder.addCase(getMyBaskets.fulfilled, (state, action) => {
      state.myBaskets = action?.payload ?? []
      state.loader = false
    })
    builder.addCase(listBasketFetch.fulfilled, (state, action) => {
      state.listBasket = action?.payload?.listBasket ?? []
      state.loader = false
    })    
    builder.addCase(fetchListHoldings.fulfilled, (state, action) => {
      state.listHoldings = action?.payload ?? []
      state.loader = false
    })
    builder.addCase(detailsBasketFetch.fulfilled, (state, action) => {
      state.detailsBasket = action?.payload ?? []
      state.loader = false
    })
    builder.addCase(addBasketFetch.fulfilled, (state, action) => {      
      state.loader = false
    })
    builder.addCase(previewBasketFetch.fulfilled, (state, action) => {      
      state.loader = false
    })
    builder.addCase(closeBasketFetch.fulfilled, (state, action) => {      
      state.loader = false
    })
    builder.addCase(openBasketFetch.fulfilled, (state, action) => {      
      state.loader = false
    })
    builder.addCase(fetchSetSipStatus.fulfilled, (state, action) => {      
      state.loader = false
    })
  },
})

export const { setSelect,setTrackParam, setLoader,setproAppForm,setHomeTrackListLoader, setPageNumber,setDropDownOpen, setPageSize, setSearch } =
    Basket.actions

export default Basket.reducer